import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CareersComponent } from './components/careers/careers.component';
import { RokuShopComponent } from './components/roku-shop/roku-shop.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component'
import { BlogsComponent} from './components/blogs/blogs.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { HrPageComponent } from './hr-page/hr-page.component';
import { LoginComponent } from './hr-page/login/login.component';
import { RegisterComponent } from './hr-page/register/register.component';
import { ProfilePageComponent } from './hr-page/profile-page/profile-page.component';
import { ControlPanelComponent } from './hr-page/control-panel/control-panel.component';
import { LeavesApprovalComponent } from './hr-page/leaves-approval/leaves-approval.component';
import { SalaryManagementComponent } from './hr-page/salary-management/salary-management.component';
import { DesignCertificateComponent } from './hr-page/design-certificate/design-certificate.component';
import { ClockInApprovalsComponent } from './hr-page/clock-in-approvals/clock-in-approvals.component';
import { HrPoliciesManagementComponent } from './hr-page/hr-policies-management/hr-policies-management.component';
import { BirthdayAlertsComponent } from './hr-page/birthday-alerts/birthday-alerts.component';
import { AchievementsPageComponent } from './hr-page/achievements-page/achievements-page.component';
import { PaySlipsComponent } from './hr-page/pay-slips/pay-slips.component';
import { ApplyCheckLeavesComponent } from './hr-page/apply-check-leaves/apply-check-leaves.component';
import { ClockInClockOutComponent } from './hr-page/clock-in-clock-out/clock-in-clock-out.component';
import { DisplayHrPoliciesComponent } from './hr-page/display-hr-policies/display-hr-policies.component';
const routes:Routes=[
  {
    path:'',redirectTo: 'home', pathMatch: 'full'
  },
  { 
    path : 'home',component:HomeComponent
  },
  {
    path:'aboutus',component:AboutUsComponent
  },
  {
    path:'careers',component:CareersComponent
  },
  {
    path:'rokushop',component:RokuShopComponent
  },
  {
    path:'termsconditions',component:TermsAndConditionsComponent
  },
  {
    path:'privacypolicy',component:PrivacyPolicyComponent
  },
  {
    path:'contactus',component:ContactUsComponent
  },
  {
    path:'blogs',component:BlogsComponent
  },
  {
    path:'blogs/HowRokusZeroCommissionModelBenefitsDriversInHyderabad',component:BlogDetailsComponent
  },
  {
    path:'blogs/RevolutionizingAutoAndTaxiServices',component:BlogDetailsComponent
  },
  {
    path:'blogs/TransformingTransportation',component:BlogDetailsComponent
  },
  { path:'hrpage', component : HrPageComponent, children : 
    [
      { path : '',redirectTo : 'login', pathMatch : "full"},
      { path : 'login', component : LoginComponent},
      { path : 'register', component : RegisterComponent},
      { path : 'profilepage', component : ProfilePageComponent},
      { path : 'controlpanel', component : ControlPanelComponent},
      { path : 'leavesapproval', component : LeavesApprovalComponent},
      { path : 'salarymanagement', component : SalaryManagementComponent},
      { path : 'designcertificate', component : DesignCertificateComponent},
      { path : 'clockinapprovals', component : ClockInApprovalsComponent},
      { path : 'hrpoliciesmanagement', component : HrPoliciesManagementComponent},
      { path : 'birthdayalerts', component : BirthdayAlertsComponent},
      { path : 'achievementspage', component : AchievementsPageComponent},
      { path : 'payslips', component : PaySlipsComponent},
      { path : 'applycheckleaves', component : ApplyCheckLeavesComponent},
      { path : 'clockinclockout', component : ClockInClockOutComponent},
      { path : 'displayhrpolicies', component : DisplayHrPoliciesComponent},
    ]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }