import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-join-the-movement',
  templateUrl: './join-the-movement.component.html',
  styleUrls: ['./join-the-movement.component.css']
})
export class JoinTheMovementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  redirectToForm():void{
    const formurl = '';
    window.open(formurl, '_blank');
  }

}
