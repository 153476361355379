import { Component, Input, OnInit } from '@angular/core';

export class ContentDescriptionsData{
  content_id? : string;
  publishedDate? : string;
  mainHeading : string;
  mainHeading_text? : string;
  theDetails : theDetails[];
}
export class theDetails{
  subHeading?: string;
  ImageSrc?:string;
  description: theLines[];
}
export class theLines{
  list? : any[];
  isList? : boolean;
  isOL? : boolean;
  value : string;
}

@Component({
  selector: 'app-content-descriptions',
  templateUrl: './content-descriptions.component.html',
  styleUrls: ['./content-descriptions.component.css']
})
export class ContentDescriptionsComponent implements OnInit {
  mainHeading;
  mainHeading_text;
  theDetails;

  @Input() ContentDescriptionsDetails : ContentDescriptionsData;
  constructor() { 
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }
  }
  screenWidth;
  screenHeight;

  ngOnInit(): void {
  }

}
