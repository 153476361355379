<div class="careers_page">
    <div class="container">
        <div class="heading_line"><mat-divider></mat-divider></div>
        <div class="heading">Careers</div>
        <div class="heading_line"><mat-divider></mat-divider></div>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4 content" *ngFor="let career of listOfCareers">
            <div class="careers_image_container" (click)="toggleOverlay(career)" >

                <img class="careers_image" [src]="career.imageUrl" alt="">
                <div class="careers_matter">
                    <div class="careers_name">{{career.name}}</div>
                    <div>
                        <div style="display: inline-block;text-align: left;font-weight: bold;">
                            <div class="careers_experience">Experience </div>
                            <div class="careers_salary" *ngIf="career.salary">Salary </div>

                            <div class="careers_incentive" *ngIf="career.incentive">Incentives </div>
                        </div>
                        <div style="display: inline-block;">
                            <div class="careers_experience">&nbsp;: </div>
                            <div class="careers_salary" *ngIf="career.salary">&nbsp;: </div>
                            <div class="careers_incentive" *ngIf="career.incentive">&nbsp;: </div>
                        </div>
                        <div style="display: inline-block;text-align: left;">
                            <div class="careers_experience">&nbsp;{{career.experience}}</div>
                            <div class="careers_salary" *ngIf="career.salary">&nbsp;{{career.salary}}</div>
                            <div class="careers_incentive" *ngIf="career.incentive">&nbsp;{{career.incentive}}</div>
                        </div>
                    </div>
                </div>
                <div class="overlay" [class.show]="career.showOverlay">
                    <div class="role_heading">Job Description <mat-icon class="cancel_symbol">cancel</mat-icon></div>
                    <div class="underline" ></div>
                    <div class="role_description">{{career.description}}</div>
                    <div class="careers_showMore">
                        <button mat-button style="background-color: white;border: none;border-radius: none;color: #fe5731;"
                            (click)="openShowMore(career.name)">Show More
                            <mat-icon style="color: #fe5731; font-size: 24px;"
                                class="link">keyboard_double_arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>