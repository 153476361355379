import { Component, OnInit } from '@angular/core';
import { ContentDescriptionsData } from 'src/app/helpers/content-descriptions/content-descriptions.component';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor() { }
  ContentDescriptionsData;

  ngOnInit(): void {
    let data: ContentDescriptionsData={
      mainHeading: "Consumer Terms and Conditions",
      mainHeading_text: "",
      theDetails: [
        {
          subHeading: "",
          description: [
            {
            value: "This Website/Mobile Application i.e. Roku Digital App (the “App”) is owned by Roku Digital (the “Company”). By using this App, you agree to be bound by the terms and conditions (the “Terms and Conditions”) set forth below. If You do not accept these Terms and Conditions, please do not use this App. The Company may, in its sole discretion, revise these Terms and Conditions at any time. We shall endeavor to notify you in case of any material changes in our Terms and Conditions.          ",
            },
            {
            value:"This document is an electronic record in terms of the Information Technology Act, 2000, and rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.",
            },
            {
              value:"The Company provides technology-based software for finding auto-rickshaws, Taxis, Metros, and other modes of transportation services (“Vehicle”) to you (“You” or “Users”) through the App so that you can avail services offered by third party drivers or vehicle operators Drivers who are on-boarded on the Company’s App. These Terms shall govern the relationship between you (the customer) and the Company in relation to the software.",
            }
            

          ]
          },
          {
          subHeading: "REGISTRATION AND ACCOUNT",
          description:[
            {
              value:"In order to use the software, you must maintain an active personal user account with us. Account registration requires you to provide certain personal information, which may include your name, address, mobile phone number, age, and any other information as prompted by the App while registering on the App (“Registration Data”).",
        },
        {
          value:"You shall ensure that the Registration Data provided by You is accurate, complete, current, valid, true, and is updated from time to time. We shall bear no liability for false, incomplete, or incorrect Registration Data provided by You.",
        },
        {
          value:"This App is intended solely for users who are eighteen (18) years of age or older and competent to contract within the meaning of the Indian Contract Act, 1872.",
        },
        {
          value:"You are responsible for maintaining the confidentiality of Your Registration Data and will be liable for all activities and transactions that occur through Your account, whether initiated by You or any third party. Your account cannot be transferred, assigned, or sold to a third party. We shall not be liable for any loss that You may incur as a result of any unauthorized third party using Your account, either with or without Your knowledge.",
        },
        {
          value:"We reserve the right to suspend or terminate Your account with immediate effect and for an indefinite period if we have a reason to believe that the Registration Data or any other data provided by You is incorrect or false, or that the security of Your account has been compromised in any way, or if we suspect unauthorized use of your account, or for any other reason we may find just or equitable.",
        },
        {
          value:"We allow you to open only one account in association with the Registration Data provided by You. In case of any unauthorized use of Your account please immediately reach us at support@roku.taxi",
        }
        ]
        },
   
        {
          subHeading: "SOFTWARE",
          description:[
            {
              value:"The software will enable You to find vehicles of Drivers or other public mobility service providers who are willing to offer their services. ",
            },
            {
              value: "You further agree that:"
            },
            {
              list: [
                {
                  value: "You will only use the software or download the App for your sole, personal use and will not resell or assign it to a third party; "
                },
                {
                  value: "You will not use the software for unlawful purposes;"
                },
                {
                  value: "You will not try to harm the software in any way whatsoever; "
                },
                {
                  value: "You will comply with all relevant applicable law;"
                }
              ],
              isOL: true,
              isList: true,
              value: ""
            },
            {
              value:"Company bears no responsibility and liability for non-completion of rides, delays, and losses suffered by you or caused to you as a consequence of the breakdown of the Vehicle. The provisioning and quality of Driver services are solely the responsibility of the Driver and are as per your agreement with the Driver, and the Company has no control over such Driver services. Any dispute between you and the Driver is your responsibility, and the Company shall not be liable for the same.",
            },
            {
              value:"Company shall, upon receiving the booking request from you, will show the available Vehicles of Drivers who are willing to provide their services. However, the Company shall not be responsible for arranging any Driver services for you. In the event the booking is confirmed, you shall check the booking details, including but not limited to pick up time and pick up place, and if there is incorrect detail, the same needs to be informed to the Driver.",
            },
            {
              value:"If You leave any goods in the Vehicle, it shall be reported immediately to the Driver. We shall not be responsible for any loss or damage caused to such items.",
            }
          ]
        },
        {
          subHeading: "PAYMENT",
          description:[
            {
              value:"You shall be required to pay charges to the Driver for the services used by you to the Driver directly or to the service provider before availing their services. The estimated rate of the services shall be notified to You while placing the booking request on the Roku Digital App. You may be facilitated with a copy of the fare receipt by the drivers, for the services provided to you by them.",
            },
            {
              value:"The fare is based on the respective State Government Rules factoring charges applied by the Driver or the respective public transportation service providers and is not determined by the Company.",
            }
          ]
        },
        {
          subHeading:"LICENSE",
          description:[
            {
              value:"You shall not (i) license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the software or app in any way; (ii) modify or make derivative works based upon the software or app; (iii) create internet “links” to the software or “frame” or “mirror” any app on any other server or wireless or internet-based device; (iv) reverse engineer or access the app in order to (a) design or build a competitive product or software, (b) design or build a product using similar ideas, features, functions or graphics of the software or app, or (c) copy, reproduce, record, or make available to the public any ideas, features, functions or graphics of the software or app, or (v) launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders the operation and/or performance of the software or app.",
            },
            {
              value:"You shall not: (i) send spam or otherwise duplicative or unsolicited messages on the software in violation of applicable laws; (ii) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights, if applicable; (iii) send or store material containing software viruses, worms, trojan horses or other harmful computer code, files, scripts, agents or programs; (iv) interfere with or disrupt the integrity or performance of the app or software or the data contained therein; or (v) attempt to gain unauthorized access to the app or software or its related systems or networks.",
            },
            {
              value:"company will have the right to investigate and prosecute violations of any of the above to the fullest extent permitted under law. company shall cooperate with law enforcement authorities in prosecuting users who violate these terms and conditions.",
            }
          ]
        },
        {
          subHeading:"CONTENT",
          description:[
            {
              value:"Company may in its sole discretion permit you or you may be able to submit, upload, publish content and information, including comments and feedback related to the software or app (“user content”). any user content provided by you remains your property. however, by providing user content, you grant company a worldwide, perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such user content in all formats and distribution channels now known or hereafter devised without further notice to or consent from you, and without the requirement of payment to you or any other person or entity. you represent and warrant that:",
            },
            {
              list: [
                {
                  value: "you either are the sole and exclusive owner of all user content or you have all rights, licenses, consents and releases necessary to grant company the license to the user content as set forth above. "
                },
                {
                  value: "neither the user content nor your submission, uploading, publishing or otherwise making available of such user content nor company’s use of the user content as permitted herein will infringe, misappropriate or violate a third party’s intellectual property or proprietary rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation. you agree to not provide user content on the app or otherwise that is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by company in its sole discretion, whether or not such material may be protected by law. company may, but shall not be obligated to, review, monitor, or remove user content provided to company, at its sole discretion and at any time and for any reason, without notice to you. "
                }
              ],
              isOL: true,
              isList: true,
              value: ""
            }
          ]
        },
        {
          subHeading: "INTELLECTUAL PROPERTY",
          description:[
            {
              value:"Company shall own all right, title and interest, including all related intellectual property rights, in and to ",
            },
            {
              list: [
                {
                  value: "The application, software and any suggestions, ideas, enhancement requests, feedback, recommendations or any other offering; "
                },
                {
                  value: "Text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code; or "
                },
                {
                  value: "Other information provided by you or any other party relating to the application or the software."
                }
              ],
              isOL: true,
              isList: true,
              value: ""
             },
            {
              value:"These terms and conditions do not constitute a sale and do not convey to you any rights of ownership in or related to the application or the software, or any intellectual property rights owned by the company. you shall be solely responsible for any violations of any laws and for any infringements of any intellectual property rights caused by use of the software or the application.",
            }

          ]
        },
        {
          subHeading:"DISCLAIMER",
          description:[
            {
              value:"Company does not warrant that the app will operate error-free or that the app and its server are free of computer viruses or other harmful material.",
            },
            {
              value:"The software and app are provided on an “as is”, “as available basis”. company and its affiliates, employees, agents, suppliers and licensors, disclaim all warranties of any kind, including but not limited to implied warranties of merchantability or fitness for a particular purpose. the company and its affiliates, employees, agents, suppliers and licensors make no representations or warranties as to the accuracy, reliability of the material or content of any information provided through the application. the company and its affiliates, employees, agents, suppliers and licensors are not liable, and expressly disclaim any liability, for the content of any data transferred either from you while using the app. no advice or information given by company or company's representatives shall create a warranty.",
            }
          ]
        },
        {
          subHeading:"BY INDEMNITY",
          description:[
            {
              value:"By using this app, you agree to indemnify, defend and hold harmless the company and its affiliates or its officers, directors, employees, agents from and against any and all claims, damages, losses, liabilities, suits, actions, demands, proceedings (whether legal or administrative), and expenses (including, but not limited to, reasonable attorney's fees) threatened, asserted, or filed by a third party against the company or its officers, directors, employees, agents arising out of or relating to ",
            },
            {
              list: [
                {
                  value: "your use of the software or application, "
                },
                {
                  value: "Any breach or violation by you of terms and conditions;"
                },
                {
                  value: "Any of your acts or omissions (including without limitation fraud, negligence or willful misconduct), except to the extent any of the foregoing directly results from company's own gross negligence or willful misconduct. company reserves the right to assume or participate, at your expense, in the investigation, settlement and defense of any such action or claim."
                }
              ],
              isOL: true,
              isList: true,
              value: ""
            }
          ]
        },
        {
          subHeading:"LIMITATION OF LIABILITY",
          description:[
            {
              value:"Company shall not be liable for indirect, incidental, special, exemplary, punitive or consequential damages, including lost profits, lost data, personal injury or property damage related to, in connection with, or otherwise resulting from any use of the software, even if company has been advised of the possibility of such damages. company shall not be liable for any damages, liability or losses arising out of:              ",
            },
            {
              list: [
                {
                  value: "your use of the software or application or your inability to access or use the software or application; "
                },
                {
                  value: "Any transaction or relationship between you and driver, even if company has been advised of the possibility of such damages. company shall not be liable for delay or failure in performance of driver services. company’s software or application may be used by you to find auto-rickshaws of drivers who are willing to offer their services, and you agree that company has no responsibility or liability to you related to any ride or services provided to you by driver."
                }
              ],
              isOL: true,
              isList: true,
              value: ""
             }
          ]
        },
        {
          subHeading:"PRIVACY",
          description:[
            {
              value:"Our collection and use of personal information in connection with the software or application is as provided in privacy policy.",
            }
          ]
        },
        {
          subHeading:"MISCELLENOUS",
          description:[
            {
              value:"If the software or application’s performance is prevented, hindered or delayed by a force majeure event and in such case our obligations under terms and conditions shall be suspended for so long as the force majeure event continues.",
            },
            {
              value:"Any controversy or claim arising out of or relating to terms and conditions, including any claim based upon or arising from an alleged tort, shall be governed by the substantive laws of India and the courts in Bangalore shall have exclusive jurisdiction.",
            },
            {
              value:"If any provision or portion of terms and conditions shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of remaining provisions and all such remaining provisions or portions (unless otherwise specified) thereof shall remain in full force and effect. company’s failure or delay in exercising its right or remedy hereunder shall not operate as a waiver nor shall any single or partial exercise of any right or remedy by company preclude from further exercise thereof or the exercise of any other right or remedy. contact information",
            },
            {
              value:"If you have any questions about terms and conditions or the software or application, please contact us at support@roku.taxi. ",
            }
          ]
        }

      ]

    }
    this.ContentDescriptionsData=data
  }

}
