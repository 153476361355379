import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roku-shop',
  templateUrl: './roku-shop.component.html',
  styleUrls: ['./roku-shop.component.css']
})
export class RokuShopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
