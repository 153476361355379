import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule} from './material';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CareersComponent, ShowMoreDailog } from './components/careers/careers.component';
import { FooterComponent } from './helpers/footer/footer.component';
import { HeaderComponent } from './helpers/header/header.component';
import { SocialLinksComponent } from './helpers/social-links/social-links.component';
import { ContentDescriptionsComponent } from './helpers/content-descriptions/content-descriptions.component';
import { RokuShopComponent } from './components/roku-shop/roku-shop.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { TextDivisionComponent } from './helpers/text-division/text-division.component';
import { ImageDivisionComponent } from './helpers/image-division/image-division.component';
import { JoinTheMovementComponent } from './components/join-the-movement/join-the-movement.component';
import { BackgroundImageComponent } from './components/background-image/background-image.component';
// import { SplitContentComponent } from './helpers/split-content/split-content.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component'
import { BlogsComponent} from './components/blogs/blogs.component';
import { HrPageModule } from './hr-page/hr-page.module';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    CareersComponent,
    FooterComponent,
    HeaderComponent,
    SocialLinksComponent,
    ContentDescriptionsComponent,
    RokuShopComponent,
    ContactUsComponent,
    TextDivisionComponent,
    ImageDivisionComponent,
    JoinTheMovementComponent,
    BackgroundImageComponent,
    // SplitContentComponent, 
    BlogDetailsComponent,
    BlogsComponent,ShowMoreDailog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HrPageModule,
    BrowserAnimationsModule,MaterialModule,CommonModule,HttpClientModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl : 'never'}),
  ],
  entryComponents: [ShowMoreDailog],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
