import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-hr-policies',
  templateUrl: './display-hr-policies.component.html',
  styleUrls: ['./display-hr-policies.component.css']
})
export class DisplayHrPoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
