<div class="background_image_page">
    <div class="image">
        <img class="img" [src]="screenWidth > 600 ? '/assets/Background.jpg' : '/assets/Background_mobilescreen.jpg'">
    </div>
</div>
<div class="card_wrap">
    <div class="box_wrap">
        <div class="header_text">
            Empowering Drivers,
            Rewarding users
        </div>
        <div class="data">
            "Roku Rides: Where Every Journey Counts - Boosting Drivers, Rewarding Riders,<br>Building a Connected
            Community!"​
        </div>
        <div class="getit">
            <button class="button" (click)="redirectToPlayStore()">Get it now</button>
        </div>
    </div>
</div>