import { Component, OnInit } from '@angular/core';
import { ContentDescriptionsData } from '../../helpers/content-descriptions/content-descriptions.component';
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  constructor() { }
  blogsData: ContentDescriptionsData[] = [
    {
      content_id: "blog_1",
      publishedDate : "07 - Apr - 2024",
      mainHeading: "How Roku's Zero Commission Model Benefits Drivers in Hyderabad",
      mainHeading_text: "Searching for Zero Commission Ride Hailing Model ? Say Hi to Roku!",
      theDetails: [
        {
          subHeading: "A Game-Changer in the World of Ride-Hailing",
          description: [
            {
              value: "Welcome to the new era of ride-hailing! In Hyderabad, a revolutionary change is underway, spearheaded by Roku's groundbreaking zero commission model. If you're a driver or a passenger in this bustling city, you're in for a treat. Roku's innovative approach is transforming the landscape of transportation, empowering drivers, and reshaping the way we think about ownership and fair compensation. ",
            }
          ]
        },
        {
          subHeading: "The Challenges Faced by Drivers :",
          ImageSrc: "assets/HowRokusZeroCommissionModelBenefitsDriversInHyderabadContent.jpg",
          description: [
            {
              value: "In the conventional ride-hailing model, drivers often find themselves at the mercy of hefty commission fees. These fees can significantly eat into their earnings, making it challenging to sustain a livelihood. Moreover, drivers have little say in the decision-making process, with platforms dictating terms and conditions without much consideration for their welfare. It's a system that leaves many feeling exploited and undervalued.",
            }
          ]
        },
        {
          subHeading: "The Game-Changer in the Industry",
          description: [
            {
              value: "Roku, however, is rewriting the rules of the game. With its zero commission model, drivers in Hyderabad now have a newfound sense of empowerment. By eliminating commission fees entirely, Roku puts more money back into the pockets of hardworking drivers. This shift not only ensures fair compensation but also fosters a more equitable partnership between the platform and its drivers.",
            }
          ]
        },
        {
          subHeading: "The Game-Changer in the Industry:   Maximizing Earnings and Ownership ",
          description: [
            {
              value: "One of the most significant advantages of Roku's zero commission model is the ability for drivers to maximize their earnings. Without having to sacrifice a portion of their income to commission fees, drivers can take home a larger share of each fare. This increased revenue not only improves their quality of life but also allows them to invest in their vehicles and future.",
            },
            {
              value: "Additionally, Roku's model fosters a sense of ownership among drivers. By removing the barriers imposed by commission fees, drivers feel a greater stake in the success of the platform. This ownership mentality encourages drivers to provide excellent service, resulting in higher customer satisfaction and loyalty. Ultimately, it's a win-win situation for both drivers and passengers alike.",
            }
          ]
        },
        {
          subHeading: "The Role of Driver Unions",
          description: [
            {
              value: "In this new era of ride-hailing, driver unions play a crucial role in advocating for the rights and welfare of drivers. With Roku's zero commission model setting a precedent for fair compensation, driver unions in Hyderabad are empowered to negotiate better working conditions and benefits for their members. Together, drivers stand stronger, ensuring that their voices are heard and respected.",
            }
          ]
        },
        {
          subHeading: "A Bright Horizon Awaits :",
          description: [
            {
              value: "As Roku's zero commission model continues to gain traction in Hyderabad, the future of transportation in the city looks brighter than ever. With drivers reaping the benefits of fair compensation and ownership, can expect a higher quality of service and reliability and we may soon see widespread shift towards more equitable models across the industry.",
            },
            {
              value: "Say goodbye to hefty commission fees and hello to fair compensation and ownership. Join us as we explore the transformative impact of this groundbreaking approach."
            }
          ]
        },
      ]
    },
    {
      content_id: "blog_2",
      publishedDate : "07 - Apr - 2024",
      mainHeading: " Revolutionizing Auto and Taxi Services",
      mainHeading_text: "Charting Success: Exploring How Roku's Driver- Centric Platform Empowers Auto and Taxi in Hyderabad",
      theDetails: [
        {
          subHeading: "Introduction ",
          description: [
            {
              value: "In the City of Pearls - Hyderabad, auto and taxi drivers navigate through crowded streets with determination and resilience. Their daily struggles and challenges can often go unnoticed, but a beacon of hope has emerged in the form of Roku's driver-centric platform. This blog post explains how Roku is transforming the lives of these hardworking individuals by empowering them with innovative technology and unmatched support.",
            }
          ],
          ImageSrc: "assets/RevolutionizingAutoAndTaxiServicesContent.jpg"
        },
        {
          subHeading: "Features and benefits of the platform",
          description: [
            {
              list: [
                {
                  value: "Real-time navigation assistance",
                },
                {
                  value: "Access to a network of fellow drivers",
                },
                {
                  value: "Instant notification of potential ride opportunities",
                },
                {
                  value: "Transparent payment system for fair compensation",
                },
                {
                  value: "Transforming Lives Through Support and Community",
                }
              ],
              isOL: false,
              isList: true,
              value: ""
            }
          ]
        },
        {
          subHeading: "Creating a sense of belonging",
          description: [
            {
              value: "Roku's platform has created a community where drivers can share their experiences, seek advice, and uplift each other during challenging times.",
            },
          ]
        },
        {
          subHeading: "Providing financial stability",
          description: [
            {
              value: "The platform has enabled drivers to plan their days better, leading to 100% earnings and financial security for themselves and their families.",
            },
            {
              value: "Ensuring safety on the road",
            },
            {
              value: "With real-time navigation assistance and instant alerts, drivers can now focus on their job without compromising on their safety.",
            }
          ]
        },
        {
          subHeading: "Conclusion:",
          description: [
            {
              value: "As we reflect on the impact of Roku's driver-centric platform on auto and taxi drivers in Hyderabad, it becomes clear that technology has the power to uplift and empower communities. By providing innovative solutions, support, and a sense of camaraderie, Roku is charting a path to success for these drivers. Let us continue to support initiatives that prioritize the well-being and advancement of those who keep our cities moving forward.",
            }
          ]
        },

      ]
    },
    {
      content_id: "bolg_3",
      publishedDate : "07 - Apr - 2024",
      mainHeading: "Transforming Transportation",
      theDetails: [
        {
          subHeading: "Here's How Roku's Drivers Union-Owned App is Revolutionizing Transportation in Hyderabad ",
          description: [
            {
              value: "Hyderabad is ever growing and navigating through the city's traffic is a difficult task, with technology revolutionizing the transportation industry there's one such innovative solution ROKU a union-owned app developed for drivers, which has transformed the way people commute in the city. Let's look deeper into how this app has changed the transportation landscape in Hyderabad.",
            }
          ],
          ImageSrc: "assets/TransformingTransportationContent.jpg"
        },
        {
          subHeading: "The Start of Roku's Drivers Union-Owned App",
          description: [
            {
              value: "Roku, a prominent union of drivers in Hyderabad, recognized the need for a more efficient and driver-centric app-based transportation service.",
            },
            {
              value: " Fueled by their passion to empower drivers and enhance the commuting experience for passengers, Roku's drivers came together to create a unique app tailored to address the specific needs of the local community."
            }
          ]
        },
        {
          subHeading: "How the App Works",
          description: [
            {
              value: "The Roku app prioritizes the welfare of drivers by offering fair wages, flexible working hours, and a supportive community.",
            },
            {
              value: "Passengers can easily book rides through the user-friendly interface, knowing that they are contributing to a sustainable and equitable transportation system.",
            }
          ]
        },
        {
          subHeading: "Benefits for Drivers and Passengers Alike",
          description: [
            {
              value: "Drivers experience increased job satisfaction and financial stability through fair compensation and improved working conditions.",
            },
            {
              value: "Passengers enjoy a reliable and affordable transportation option while supporting a driver-owned initiative that values transparency and accountability.",
            },
            {
              value: "Impact on the Local Transportation Industry ",
            },
            {
              value: "The introduction of Roku's union-owned app has disrupted the traditional model of transportation services in Hyderabad.",
            },
            {
              value: "By emphasizing driver welfare and community collaboration, this app has set a new standard for ethical and sustainable transportation practices in the city.",
            }
          ]
        },
        {
          subHeading: "Conclusion:",
          description: [
            {
              value: "As we witness the transformation brought about by Roku's drivers union-owned app, it becomes evident that innovation coupled with a strong sense of community can truly revolutionize the way we commute. By supporting initiatives like this, we not only improve the lives of drivers but also contribute to a more inclusive and sustainable transportation ecosystem in Hyderabad. Let's embrace the opportunities that lie ahead and explore the endless possibilities that await us in this driver-driven revolution.",
            }
          ]
        },
      ]
    }
  ]
  ContentDescriptionsData;
  ngOnInit(): void {
    var selectedBlog = localStorage.getItem("selectedBlog");
    console.log(selectedBlog);
    if(selectedBlog == "blog_1"){
      let data : ContentDescriptionsData = this.blogsData[0];
      this.ContentDescriptionsData = data;
    }else if(selectedBlog == "blog_2"){
      let data : ContentDescriptionsData = this.blogsData[1];
      this.ContentDescriptionsData = data;
    }else if(selectedBlog == "blog_3"){
      let data : ContentDescriptionsData = this.blogsData[2];
      this.ContentDescriptionsData = data;
    }
    console.log(this.ContentDescriptionsData)
  }
}
