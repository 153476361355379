import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.css']
})
export class BackgroundImageComponent implements OnInit {

  constructor() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }
  screenWidth : number;
  screenHeight : number;
  ngOnInit(): void {
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.screenHeight = event.target.innerHeight;
  }
  redirectToPlayStore(): void {
    const playStoreUrl = `https://play.google.com/store/apps/details?id=product.customer.rokuCustomer&pcampaignid=web_share`;
    window.open(playStoreUrl, '_blank');
  }
}
