import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  listOfBlogs = [
    { id: "blog_1", publishedDate: "07 - Apr - 2024", name: "How Roku's Zero Commission Model Benefits Drivers in Hyderabad", imageUrl: 'assets/HowRokusZeroCommissionModelBenefitsDriversInHyderabad.jpg' },
    { id: "blog_2", publishedDate: "07 - Apr - 2024", name: "Revolutionizing Auto and Taxi Services", imageUrl: 'assets/RevolutionizingAutoAndTaxiServices.jpg' },
    { id: "blog_3", publishedDate: "07 - Apr - 2024", name: "Transforming Transportation", imageUrl: 'assets/TransformingTransportation.jpg' },
  ];
  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  showMore(blogid: string): void {
    console.log(blogid)
    localStorage.setItem("selectedBlog", blogid);
    if (blogid == "blog_1") {
      this.router.navigate(['/blogs/HowRokusZeroCommissionModelBenefitsDriversInHyderabad']);
    } else if (blogid == "blog_2") {
      this.router.navigate(['/blogs/RevolutionizingAutoAndTaxiServices']);
    } else if (blogid == "blog_3") {
      this.router.navigate(['/blogs/TransformingTransportation']);
    } else {
    }
  }
}
