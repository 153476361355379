import { Component, OnInit, Input} from '@angular/core';

export class ImageDivisionData{
  mainHeading : string;
  theImage : theImage[];
}
export class theImage{
  image: string;
  header: string;
  list? : any[];
  isList? : boolean;
  description: string;
}

@Component({
  selector: 'app-image-division',
  templateUrl: './image-division.component.html',
  styleUrls: ['./image-division.component.css']
})
export class ImageDivisionComponent implements OnInit {
  mainHeading;
  theImage;
  @Input() ImageDivisionDetails : ImageDivisionData;
  constructor() {
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }
  }
  screenWidth;
  screenHeight;
  ngOnInit(): void {
    console.log(this.screenHeight,this.screenWidth);
  }
}