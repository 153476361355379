<div class="header">
    <div class="laptop_view">
        <div class="row">
            <div class="col-3">
                <img src="/assets/auto.png" class="logo">
            </div>
            <div class="col-9">
                <div id="header_links">
                    <a routerLink="/home" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">HOME</a>
                    <a routerLink="/aboutus" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">ABOUT US</a>
                    <a routerLink="/careers" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">CAREERS</a>
                    <a routerLink="/blogs" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">BLOGS</a>
                        <a routerLink="/rokushop" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">ROKU SHOP</a>
                </div>
            </div>
        </div>
    <!-- <router-outlet></router-outlet> -->
    </div>
    <div class="mobile_view">
        <div class="row">
            <div class="col-3">
                <div class="icon_view"></div>
                <img src="/assets/auto.png" class="logo">
            </div>
            <div class="col-9">
                <div class="button_display">
                    <button mat-icon-button>
                        <mat-icon class="icon_display" (click)="sidenav.open()">view_headline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <mat-sidenav-container class="sidenav-container">
            <mat-sidenav style="background-color: #FEFEFF; overflow-x: hidden;position: fixed;" #sidenav [opened]="screenWidth < 840" mode = "over" class="sidenav mat-elevation-z3" >
                <mat-nav-list>
                    <!-- <mat-expansion-panel class="mat-elevation-z0" style="background-color: #D5DBDB;" #tracker (opened)="tracker.expanded = true" (closed)="tracker.expanded = false">
                        <mat-expansion-panel-header style="padding: 0px 15px 0px 15px;">
                            <mat-panel-title style="font-size: 15px;">
                                <label>lists</label>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-nav-list>
                            <mat-list-item (click)= "[sidenav.close(), SelectedReport(9)]" routerLinkActive="active-list-item" [routerLink]="['list1']"><li>list1</li></mat-list-item>
                            <mat-list-item  (click)= "[sidenav.close(), SelectedReport(10)]" routerLinkActive="active-list-item" [routerLink]="['list2']"><li>list2</li></mat-list-item>
                            <mat-list-item (click)= "[sidenav.close(), SelectedReport(11)]" routerLinkActive="active-list-item" [routerLink]="['list3']"><li>list3</li></mat-list-item>
                        </mat-nav-list>
                    </mat-expansion-panel> -->
                    <div class="icon_view">
                    <img src="/assets/auto.png" class="logo_sidenav">
                    </div>
                    <mat-list-item (click)= "[sidenav.close(), SelectedFile(0)]" routerLinkActive="active-list-item" [routerLink]="['home']">Home</mat-list-item>
                    <mat-list-item (click)= "[sidenav.close(),SelectedFile(1)]" routerLinkActive="active-list-item" [routerLink]="['aboutus']">About Us</mat-list-item>
                    <mat-list-item (click)= "[sidenav.close(),SelectedFile(2)]" routerLinkActive="active-list-item" [routerLink]="['careers']">Careers</mat-list-item>
                    <!-- <mat-list-item (click)= "[sidenav.close(),SelectedFile(3)]" routerLinkActive="active-list-item" [routerLink]="['rokushop']">Roku Shop</mat-list-item> -->
                    <!-- <mat-list-item (click)= "[sidenav.close(),SelectedFile(4)]" routerLinkActive="active-list-item" [routerLink]="['termsconditions']">Terms Conditions</mat-list-item> -->
                    <mat-list-item (click)= "[sidenav.close(),SelectedFile(6)]" routerLinkActive="active-list-item" [routerLink]="['blogs']">Blogs</mat-list-item>
                    <mat-list-item (click)= "[sidenav.close(),SelectedFile(3)]" routerLinkActive="active-list-item" [routerLink]="['rokushop']">Roku Shop</mat-list-item>
                    <!-- <mat-list-item (click)= "[sidenav.close(),SelectedFile(6)]" routerLinkActive="active-list-item" [routerLink]="['blogs']">Blogs</mat-list-item> -->
                </mat-nav-list>
            </mat-sidenav>
            <mat-sidenav-content style="background-color: #FEFEFF;">
                <!-- <router-outlet></router-outlet> -->
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
