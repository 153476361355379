<!-- ////////// Telecaller ////////// -->

<div *ngIf="Telecaller" class="Telecaller">
    <div class="main_header">Telecaller (Female Candidates Only)</div>
    <div class="underline"></div>
    <div class="side_header" style="display: inline;">Company : </div>
    <p style="display: inline;">Roku Digital</p><br><br>
    <div class="side_header" style="display: inline;">Location : </div>
    <p style="display: inline;"> Above DCB bank, 5th floor, Back side Radhika theater Ecil</p><br><br>

    <div class="side_header" style="display: inline;">Salary : </div>
    <p style="display: inline;">₹15,000 to ₹18,000 per month + Attractive Incentives</p><br><br>

    <!-- <div class="side_header">About Roku Digital : </div>
    <p>Roku Digital is a leading digital solutions provider, renowned for our comprehensive services in various sectors,
        including financial products. Our commitment to excellence and customer satisfaction drives us to seek talented
        individuals who are passionate about sales and client relations.</p> -->
    <div class="side_header">Job Description</div>
    <div>Roku Digital is seeking enthusiastic and motivated female Telecallers to join our dynamic team. Whether you are
        an experienced professional or a fresher looking to start your career, we welcome you to be part of our growth
        journey. This role involves engaging with potential customers, understanding their needs, and providing them
        with appropriate solutions.</div><br>

    <div class="side_header">Key Responsibilities:</div>

    <!-- <div class="side_header">Customer Outreach : </div> -->
    <ul>
        <li><span class="side_header">Customer Outreach : </span>Make outbound calls to prospective customers to
            introduce and promote our products/services.</li>


        <!-- <div class="side_header">Lead Generation:</div> -->

        <li> <span class="side_header">Lead Generation : </span>Identify and qualify potential leads through targeted outreach
                and follow-up calls.</li>


        <!-- <div class="side_header">Customer Support : </div> -->

        <li><span class="side_header">Customer Support : </span>Address customer inquiries, provide information about
                products/services, and resolve any issues or concerns.</li>


        <!-- <div class="side_header">Sales Conversion : </div> -->

        <li><span class="side_header">Sales Conversion : </span>Persuade potential customers to purchase products/services,
                achieving monthly sales targets.</li>


        <!-- <div class="side_header">Relationship Management : </div> -->

        <li><span class="side_header">Relationship Management : </span>Build and maintain positive relationships with customers
                to foster loyalty and repeat business.
        </li>


        <!-- <div class="side_header">Record Keeping : </div> -->

        <li><span class="side_header">Record Keeping : </span>Accurately document customer interactions and sales activities in
                the CRM system.</li>

        <!-- <div class="side_header">Feedback Collection</div> -->

        <li><span class="side_header">Feedback Collection : </span>Gather customer feedback to improve service quality and
                customer satisfaction.</li>

        <!-- <div class="side_header">Collaboration</div> -->

        <li><span class="side_header">Collaboration : </span>
                Work closely with the sales and marketing teams to align strategies and achieve common goals.
        </li>
    </ul>

    <div class="side_header">Qualifications:</div>
    <ul>
        <li><span class="side_header">Education : </span>High school diploma or equivalent (Bachelor’s degree preferred)</li>
        <li><span class="side_header">Experience : </span>Previous telecalling or customer service experience is a plus, but not required.</li>
        <li><span class="side_header">Skills : </span>Excellent communication skills, persuasive ability, and a positive attitude.</li>
        <li><span class="side_header">Languages : </span>Proficiency in Telugu, English, Hindi is required.</li>
        <li><span class="side_header">Technology : </span>Basic computer skills.</li>
    </ul>

    <div class="side_header"> Benefits:</div>
    <ul>
        <li>Competitive salary ranging from ₹15,000 to ₹18,000 per month.</li>
        <li>Attractive performance-based incentives.</li>
        <li>Opportunity for career growth and professional development.</li>
        <li>Friendly and supportive work environment..</li>
        <li>Training and mentorship programs.</li>
    </ul>

    <div class="side_header">How to Apply:</div>
    <p>Interested candidates are invited to submit their resume and a cover letter detailing their experience and
        suitability for the role to <a href="mailto:rasagnya@roku.taxi">rasagnya@roku.taxi</a> or 8977607759.</p>
    <p>Join Roku Digital and contribute to our mission of providing exceptional financial solutions to our clients!</p>
</div>


<!-- ///// Sales Manager /////// -->

<div *ngIf="Sales_Manager" class="Sales_Manager">
    <div class="main_header">Sales Manager</div>
    <div class="underline"></div>
    <div class="side_header" style="display: inline;">Location : </div>
    <p style="display: inline;">Roku Digital( Sharkify Technology)</p><br><br>

    <div class="side_header" style="display: inline;">Salary : </div>
    <p style="display: inline;">₹15,000 per month (plus petrol allowance and fair incentives)</p><br><br>

    <div class="side_header" style="display: inline-block;">Experience : </div>
    <p style="display: inline;">Minimum 2 years.<br><br>

    <div class="side_header" style="display: inline-block;">Location :</div>
    <p style="display: inline;"> SM plaza, Ecil ( for interviews)<br>Office location: AWFIS
        2nd and 3rd floor
        Malabar gold building, Somajiguda.</p><br><br>

    <div class="side_header">About Roku Digital : </div>
    <p>Roku Digital is a leading digital solutions provider, renowned for our comprehensive services in various sectors,
        including financial products. Our commitment to excellence and customer satisfaction drives us to seek talented
        individuals who are passionate about sales and client relations.</p>

    <div class="side_header">Job Description:</div><br>

    <div class="side_header">1. Sales Strategy and Execution:</div>
    <ul>
        <li>Develop and implement effective sales strategies to achieve sales targets for housing loans, vehicle loans,
            and
            health insurance products.</li>
        <li>Identify potential clients and market segments to expand the customer base.</li>
    </ul>

    <div class="side_header">2. Client Acquisition and Relationship Management:</div>
    <ul>
        <li>Generate leads and acquire new clients through various channels, including networking, referrals, and direct
            outreach.</li>
        <li>Build and maintain strong relationships with existing and potential clients to understand their needs and
            offer
            suitable products.</li>
    </ul>

    <div class="side_header"> 3. Product Knowledge and Advisory:</div>
    <ul>
        <li> Maintain in-depth knowledge of housing loans, vehicle loans, and health insurance products.</li>
        <li>Provide clients with detailed information about product features, benefits, and terms to assist them in
            making
            informed decisions.</li>
    </ul>

    <div class="side_header">4. Sales Operations:</div>
    <ul>
        <li>Conduct sales presentations and product demonstrations to clients.</li>
        <li>Negotiate and finalize deals, ensuring all necessary documentation is completed accurately and promptly.
        </li>
        <li>Monitor and report on sales performance, analyzing trends and adjusting strategies as needed.</li>
    </ul>

    <div class="side_header">5. Collaboration and Teamwork:</div>
    <ul>
        <li>Work closely with the marketing and customer service teams to align sales efforts with overall business
            goals.
        </li>
        <li>Collaborate with colleagues to share best practices and optimize sales processes.</li>
    </ul>

    <div class="side_header">6. Compliance and Reporting:</div>
    <ul>
        <li>Ensure compliance with all regulatory requirements and company policies during the sales process.</li>
        <li>Prepare regular sales reports and forecasts for management review.</li>
    </ul>

    <div class="side_header">Qualifications:</div>
     <ul>
        <li>Minimum of 2 years of experience in sales, preferably in the financial services sector.</li>
        <li>Proven track record of achieving sales targets and driving revenue growth.</li>
        <li>Knowledge of housing loans, vehicle loans, and health insurance products is a significant advantage.</li>
        <li> Excellent communication, negotiation, and interpersonal skills.</li>
        <li>Strong analytical and problem-solving abilities.</li>
     </ul>

    <div class="side_header"> Benefits:</div>
    <ul>
        <li>Competitive salary of ₹15,000 per month.</li>
        <li>Petrol allowance to cover travel expenses.</li>
        <li>Fair incentives based on sales performance.</li>
        <li>Opportunities for professional growth and career advancement.</li>
        <li>Supportive and dynamic work environment.</li>
        <li>Training and development programs.</li>
    </ul>

    <div class="side_header">How to Apply:</div>
    <p>Interested candidates are invited to submit their resume and a cover letter detailing their experience and
        suitability for the role to <a href="mailto:rasagnya@roku.taxi">rasagnya@roku.taxi</a> or 8977607759.</p>
    <p>Join Roku Digital and contribute to our mission of providing exceptional financial solutions to our clients!</p>
</div>

<!-- /////// Back End Executive //////// -->
<div *ngIf="Back_End_Executive" class="Back_End_Executive">
    <div class="main_header">Back-End Executive
    </div>
    <div class="side_header" style="display: inline;">Company name : </div>
    <p style="display: inline;">Roku Digital</p><br><br>

    <div class="side_header" style="display: inline;">Salary : </div>
    <p style="display: inline;">₹13,000 - ₹15,000 per month (plus attractive incentives)
    </p><br><br>

    <div class="side_header" style="display: inline-block;">Experience : </div>
    <p style="display: inline;"> Fresher or experienced candidates</p><br><br>
    <div class="side_header" style="display: inline-block;">Location : </div>
    <p style="display: inline;">Above DCB bank, 5th floor, Back side Radhika theater Ecil.</p><br><br>

        <div class="side_header" style="display: inline-block;">Gender : </div>
        <p style="display: inline;">Male
        </p><br><br>

    <div class="side_header">About Roku Digital : </div>
    <p>Roku Digital is a forward-thinking digital marketing company known for its innovative solutions and client-centric approach. We are committed to delivering high-quality services and maintaining a vibrant and inclusive work environment.</p>

    <div class="side_header">Job Description:</div><br>

    <div class="side_header">1. Data Management and Processing:</div>
    <ul>
        <li>Collect, organize, and manage data from various sources to ensure it is accurate and up-to-date.</li>
        <li>Input and update data into the company's database systems with precision and attention to detail.</li>
        <li>Perform regular data audits and quality checks to maintain data integrity.</li>
    </ul>

    <div class="side_header">2.Documentation and Reporting:</div>
    <ul>
        <li>Prepare and maintain documentation, reports, and records as required by the management.</li>
        <li>Generate and distribute regular and ad-hoc reports to different departments.</li>
        <li>Ensure all documentation is stored securely and is easily accessible for future reference.
        </li>
    </ul>

    <div class="side_header"> 3.Administrative Support:</div>
    <ul>
        <li>Provide administrative support to the marketing, sales, and operations teams as needed.</li>
        <li>Handle routine administrative tasks such as filing, copying, and scanning documents.</li>
        <li> Assist in coordinating meetings, preparing agendas, and taking minutes.
        </li>
    </ul>

    <div class="side_header">4.Communication and Coordination:</div>
    <ul>
        <li> Serve as a point of contact for internal teams and external clients for back-end support related queries.
        </li>
        <li>Coordinate with different departments to ensure seamless flow of information and processes.
        </li>
        <li>Respond to emails and other communications promptly and professionally.
            </li>
    </ul>

    <div class="side_header">5. Collaboration and Teamwork:</div>
    <ul>
        <li>Monitor and maintain back-end systems to ensure they are running efficiently.
        </li>
        <li>Troubleshoot and resolve any system-related issues that may arise.</li>
        <li>Collaborate with the IT department for system upgrades and maintenance.</li>
    </ul>

    <div class="side_header">6.Process Improvement:</div>
    <ul>
        <li>Identify opportunities to streamline and improve back-end processes.
        </li>
        <li>Implement best practices and contribute to the development of new procedures.</li>
        <li>Stay updated with the latest industry trends and back-end technologies.
        </li>
    </ul>

    <div class="side_header">Qualifications:</div>
    <ul>
        <li>High school diploma or equivalent; a bachelor’s degree in a relevant field is a plus..</li>
        <li>Strong attention to detail and organizational skills.</li>
        <li>Proficiency in MS Office Suite (Word, Excel, PowerPoint).</li>
        <li> Good communication and interpersonal skills.</li>
        <li>Ability to work independently and as part of a team.</li>
        <li> Basic understanding of back-end systems and processes.</li>
        <li> Willingness to learn and adapt to new tools and technologies.</li>
    </ul>

    <div class="side_header"> Benefits:</div>
    <ul>
        <li>Competitive salary package (₹13,000 - ₹15,000 per month) with attractive incentives based on performance.</li>
        <li>Opportunity for professional growth and career advancement.</li>
        <li>Supportive and collaborative work environment.</li>
        <li>Health and wellness benefits.</li>
        <li>Training and development programs.</li>
    </ul>

    <div class="side_header">How to Apply:</div>
    <p>Interested male candidates are invited to submit their resume and a cover letter detailing their experience and suitability for the role to
        <a href="mailto: rasagnya@roku.taxi">rasagnya@roku.taxi</a> or 8977607759.</p>
    <p>Join Roku Digital and contribute to our mission of delivering exceptional digital marketing solutions!</p>
</div>


<!-- /////// Team lead Marketing /////// -->

<div *ngIf="Team_Lead_Marketing" class="Team_Lead_Marketing">
    <div class="main_header">Team Lead, Marketing</div>
    <div class="underline"></div>
    <div class="side_header" style="display: inline;">Company Name : </div>
    <p style="display: inline;">Roku Digital</p><br><br>

    <div class="side_header" style="display: inline;">Salary : </div>
    <p style="display: inline;"> ₹25,000 - ₹30,000 per month (plus attractive incentives)
    </p><br><br>

    <div class="side_header" style="display: inline-block;">Experience : </div>
    <p style="display: inline;">1+ year</p><br><br>

    <div class="side_header" style="display: inline-block;">Location : </div>
    <p style="display: inline;">Above DCB bank, 5th floor, Back side Radhika theater Ecil.</p><br><br>

    <div class="side_header">About Roku Digital : </div>
    <p>Roku Digital is a dynamic and innovative company specializing in cutting-edge digital marketing solutions. We pride ourselves on delivering exceptional results and fostering a collaborative and growth-oriented work environment</p>

    <div class="side_header">Job Responsibilities:</div><br>

    <div class="side_header">1. Leadership and Team Management:</div>
    <ul>
        <li>Lead, mentor, and motivate a team of marketing professionals to achieve individual and team goals.
        </li>
        <li>Conduct regular team meetings to review performance, set goals, and plan upcoming marketing campaigns.
        </li>
        <li>Foster a positive and productive team culture, encouraging collaboration and continuous learning.
        </li>
    </ul>

    <div class="side_header">2. Strategy Development and Execution:</div>
    <ul>
        <li>Develop and implement effective marketing strategies that align with Roku Digital’s goals and objectives.
        </li>
        <li> Coordinate with cross-functional teams to ensure cohesive and comprehensive campaign execution.
        </li>
        <li>  Analyze market trends, competitor activities, and customer insights to refine marketing strategies.</li>
    </ul>

    <div class="side_header"> 3.  Campaign Management:</div>
    <ul>
        <li>  Oversee the creation, execution, and optimization of marketing campaigns across various digital channels including social media, email, content marketing, and paid advertising.
        </li>
        <li> Ensure all campaigns are delivered on time, within scope, and within budget.
        </li>
        <li> Track and report on campaign performance metrics, using data to drive decision-making and continuous improvement.

        </li>
    </ul>

    <div class="side_header">4.  Client and Stakeholder Communication:</div>
    <ul>
        <li>Serve as the primary point of contact for key clients, understanding their needs and ensuring their satisfaction with our marketing services.
        </li>
        <li>Present campaign plans and results to clients and internal stakeholders, providing clear and actionable insights.

        </li>
        <li>Build and maintain strong relationships with clients, partners, and vendors.
        </li>
    </ul>

    <div class="side_header">5. Innovation and Creativity:</div>
    <ul>
        <li>Encourage and drive creative thinking within the team to develop innovative marketing solutions.

        </li>
        <li>Stay up-to-date with the latest industry trends, tools, and technologies to keep Roku Digital at the forefront of digital marketing.

        </li>
    </ul>

    <div class="side_header">6.  Performance Monitoring and Reporting:</div>
    <ul>
        <li> Set clear performance metrics and KPIs for the marketing team.
        </li>
        <li>Regularly review and analyze marketing data to assess the effectiveness of campaigns and identify areas for improvement.
        </li>
        <li>Prepare detailed reports and presentations on campaign performance for senior management.
        </li>
    </ul>

    <div class="side_header">Qualifications:</div>
    <ul>
        <li>Bachelor’s degree in Marketing, Business Administration, or a related field.
        </li>
        <li> Minimum of 1 year of experience in a marketing role, with a proven track record of successful campaign management.
        </li>
        <li>Strong leadership and team management skills.
        </li>
        <li>Excellent communication and interpersonal skills.
        </li>
        <li> Proficiency in digital marketing tools and platforms.
        </li>
        <li> Analytical mindset with the ability to interpret data and make data-driven decisions.

        </li>
        <li> Creative thinking and problem-solving abilities.

        </li>
    </ul>

    <div class="side_header"> Benefits:</div>
    <ul>
        <li>Competitive salary package (₹25,000 - ₹30,000 per month) with attractive incentives based on performance.
        </li>
        <li> Opportunity for professional growth and development.
        </li>
        <li>A dynamic and collaborative work environment.
        </li>
        <li>Health and wellness benefits.
        </li>
        <li> Flexible work hours and potential for remote work.
        </li>
    </ul>

    <div class="side_header">How to Apply:</div>
    <p>Interested candidates are invited to submit their resume and a cover letter detailing their experience and
        suitability for the role to <a href="mailto:rasagnya@roku.taxi">rasagnya@roku.taxi</a> or 8977607759.</p>
    <p>Join Roku Digital and be part of a team that is shaping the future of digital marketing!</p>
</div>


<!-- //////video Editing//// -->

<div *ngIf="Video_Editing" class="Video_Editing">
    <div class="main_header"> Video Editor </div>
    <div class="underline"></div>
    <div class="side_header" style="display: inline;">Company Name : </div>
    <p style="display: inline;">Roku Digital( Sharkify Technology)</p><br><br>

    <div class="side_header">Company Description :</div>
    <p>
        Roku Digital Inc is a pioneering force in the mobility landscape, focused on redefining transportation and
        creating seamless journeys for users. Based in Secunderābād, we are driving innovation, fostering community, and
        shaping the future of mobility. Join us as we empower drivers and shape the future of transportation together.
    </p>

    <div class="side_header">Role Description</div>
    <p>
        This is a full-time on-site role looking for 2+ years experience of Senior Video Editor at Roku Digital Inc. As
        a Senior Video Editor, you will be responsible for day-to-day tasks including video production, video editing,
        video color grading, motion graphics, and graphics editing. You will play a key role in creating visually
        engaging content to enhance the user experience.
    </p>

    <div class="side_header"> Qualifications :</div>
    <ul>
        <li>Video Production and Video Editing skills</li>
        <li>Video Color Grading and Motion Graphics skills</li>
        <li>Graphics editing skills</li>
        <li>Proficiency in industry-standard video editing software</li>
        <li>Experience in creating visually engaging content</li>
        <li>Strong attention to detail and ability to work under tight deadlines</li>
        <li>Excellent communication and collaboration skills</li>
        <li>Bachelor's degree in Film Production, Multimedia, or related field</li>
    </ul>

    <div class="side_header">How to Apply:</div>
    <p>Interested candidates are invited to submit their resume and a cover letter detailing their experience and
        suitability for the role to <a href="mailto:rasagnya@roku.taxi">rasagnya@roku.taxi</a> or 8977607759.</p>
    <p>Join Roku Digital and contribute to our mission of providing exceptional financial solutions to our clients!</p>
</div>
 

<!-- /////////// Digital Marketing /////////// -->

<div *ngIf="Digital_Marketing_Specialist" class="Digital_Marketing_Specialist">
    <div class="main_header"> Digital Marketing Specialist Wanted !</div>
    <div class="underline"></div>
    <div class="side_header" style="display: inline;">Company Name : </div>
    <p style="display: inline;">Roku Digital( Sharkify Technology)</p><br><br>

    <div class="side_header" style="display: inline-block;">Experience : </div>
    <p>Are you a seasoned Digital Marketing Specialist looking to take your career to the next level? We're on the
        lookout for a dynamic individual with 2 plus years of experience to join our team!</p><br>

    <div class="side_header">Key Responsibilities:</div>
    <ul>
        <li>Develop and implement digital marketing strategies to drive brand awareness and lead generation.</li>
        <li>Manage social media channels, content creation, and community engagement.</li>
        <li>Analyze campaign performance and optimize strategies for maximum impact.</li>
        <li>Collaborate with cross-functional teams to align marketing efforts with business objectives.</li>
        <li>Stay updated on industry trends and best practices to innovate our digital marketing approach.</li>
    </ul>

    <div class="side_header"> Requirements :</div>
    <ul>
        <li>Bachelor's degree in Marketing, Communications, or related field.</li>
        <li>Proven track record of success in digital marketing, with at least 2 years of experience.</li>
        <li>Proficiency in digital marketing tools such as Google Analytics, SEO/SEM, and social media management
            platforms.</li>
        <li>Strong analytical skills with the ability to interpret data and make actionable recommendations.</li>
        <li>Excellent communication and collaboration skills.</li>
        <li>Passion for staying ahead of the curve in digital marketing trends and technologies.</li>
    </ul>

    <div class="side_header">How to Apply:</div>
    <p>Interested candidates are invited to submit their resume and a cover letter detailing their experience and
        suitability for the role to <a href="mailto:rasagnya@roku.taxi">rasagnya@roku.taxi</a> or 8977607759.</p>
    <p>Join Roku Digital and contribute to our mission of providing exceptional financial solutions to our clients!</p>
</div>
<div style="text-align: right;">
    <button mat-raised-button mat-dialog-close class="close_button">Close</button>
</div>