<div class="full_page">
    <div class="user_reviews_wrap">
        <div class="text_division_container">
            <div class="heading_line"><mat-divider></mat-divider></div>
            <div class="heading"> {{UserReviewsDetails.mainHeading}}</div>
            <div class="heading_line"><mat-divider></mat-divider></div>
        </div>
        <div class="main_header_text_wrap">{{UserReviewsDetails.mainHeader_text}}</div>
        <div class="row">
            <div class="col-md-4 details_wrap" *ngFor="let proofs of UserReviewsDetails.theProofs;">
                <p class="description_text">{{proofs.description}}</p>
                <p class="name_text"><b>{{proofs.name}}</b></p>
            </div>
        </div>
    </div>
</div>