import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-birthday-alerts',
  templateUrl: './birthday-alerts.component.html',
  styleUrls: ['./birthday-alerts.component.css']
})
export class BirthdayAlertsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
