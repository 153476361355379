import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-page',
  templateUrl: './hr-page.component.html',
  styleUrls: ['./hr-page.component.css']
})
export class HrPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
