import { Component, Input, OnInit } from '@angular/core';

export class UserReviewsData{
  mainHeading : string;
  mainHeader_text : string;
  theProofs : theProofs[];
}
export class theProofs{
  description : string;
  name : string;
}
@Component({
  selector: 'app-text-division',
  templateUrl: './text-division.component.html',
  styleUrls: ['./text-division.component.css']
})
export class TextDivisionComponent implements OnInit {
  mainHeading;
  mainHeader_text;
  @Input() UserReviewsDetails : UserReviewsData;
  constructor() {
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }
  }
  screenWidth;
  screenHeight;
  theProofs;
  ngOnInit(): void {
  }
}
