<div class="blog_page">
  <div class="row">
    <div class="col-md-4 blog_content" *ngFor="let post of listOfBlogs">
      <div class="card mat-elevation-z1">
        <img class="blog_image" [src]="post.imageUrl" [alt]="post.name">
        <div class="card-body">
          <div class="blog_published_date">{{post.publishedDate}}</div>
          <div class="blog_name">{{post.name}}</div>
          <div class="blog_footer">
            <button mat-button (click)="showMore(post.id)"
              style="background-color: white;border: none;border-radius: none;color: #fe5731;">Continue Reading
              <mat-icon style="color: #fe5731;font-size: 22px;" class="link"> keyboard_double_arrow_right
              </mat-icon></button>
            <!-- Show More -->
            <!-- <div class="blogger_show_more" style="display:inline-block" (click)="showMore(post.id)">Show More </div> -->
            <!-- <div style="display:inline-block;"><mat-icon style="color: #fe5731;font-size: 16px;" class="link"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>