import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clock-in-approvals',
  templateUrl: './clock-in-approvals.component.html',
  styleUrls: ['./clock-in-approvals.component.css']
})
export class ClockInApprovalsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
