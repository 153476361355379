<div class="page">
    <div class="row" style="margin: 0px;padding: 0px;">
        <div class="col-md-6" style="padding: 0px;">
            <div class="contact" *ngIf="!showContactForm">
                <div class="contact_us_container">
                    <div class="heading_line"><mat-divider></mat-divider></div>
                    <div class="heading_text">Contact Us</div>
                    <div class="heading_line"><mat-divider></mat-divider></div>
                </div>
                <div class="contact_us_section">
                    <div class="heading">Roku Digital Inc</div>
                    <p class="sub_data">30 N Gould St Ste RSheridan, Wyoming, United States Of America, 82801</p>
                    <button class="Message" (click)="sendMessage()"><span class="whatsapp-icon"></span>Message us on
                        WhatsApp</button>
                    <div class="heading">Roku Digital</div>
                    <p class="sub_data">DCB Bank, A. S. Rao Nagar, Secunderabad, Telangana, India</p>
                    <a href="mailto:support@roku.taxi">support@roku.taxi</a>
                    <br>
                    <div class="heading">Hours</div>
                    <div (click)="Open()" style="cursor: pointer;" class="Timings_data">Open Today <div
                            class="officeHours"> {{officeHours[currentDay]}}
                        </div>
                    </div>
                    <div *ngIf="isdisplayTimings">
                        <ul class="Timings">
                            Mon 09:00 – 17:00<br>
                            Tue 09:00 – 17:00<br>
                            Wed 09:00 – 17:00<br>
                            Thu 09:00 – 17:00<br>
                            Fri 09:00 – 17:00<br>
                            Sat 09:00 – 17:00<br>
                            Sun Closed<br>
                        </ul>
                    </div>
                    <div class="Contact">
                        <button class="contact_us" (click)="showContactForm = true">Contact us</button>
                    </div>
                </div>
            </div>
            <div *ngIf="showContactForm">
                <div class="contact_form_container">
                    <div class="ContactDescription">Contact us</div>
                    <form (submit)="submitForm()">
                        <div class="form-group">
                            <input type="text" id="name" name="name" placeholder="Enter your name"
                                [(ngModel)]="formData.name">
                        </div>
                        <div class="form-group">
                            <input type="email" id="email" name="email" placeholder="Enter your email"
                                [(ngModel)]="formData.email">
                        </div>
                        <div class="form-group">
                            <textarea id="message" name="message" placeholder="Enter your message"
                                [(ngModel)]="formData.message"></textarea>
                            <div class="bottom-data">This site is protected by reCAPTCHA and the Google <a href=""
                                    class="link"> Privacy Policy </a> and <a href="" class="link"> Terms of Service
                                </a>apply</div>

                        </div>
                        <div class="SubmitDescription">
                            <button type="submit">Submit</button>
                            <a href="#" (click)="showContactForm = false" class="CancelDescription">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-6 maps_container" style="padding: 0px;">
            <iframe class="maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15222.663930439076!2d78.564095!3d17.475694!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b65ecf50bc5%3A0x58b374bf51566908!2sRoku%20Digital!5e0!3m2!1sen!2sin!4v1710153312650!5m2!1sen!2sin"></iframe>
        </div>
    </div>
</div>