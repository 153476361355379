import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-slips',
  templateUrl: './pay-slips.component.html',
  styleUrls: ['./pay-slips.component.css']
})
export class PaySlipsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
