import { Component, OnInit } from '@angular/core';
import { ContentDescriptionsData} from 'src/app/helpers/content-descriptions/content-descriptions.component'
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }
  ContentDescriptionData;

  ngOnInit(): void {
    let data: ContentDescriptionsData = {
      mainHeading: "Privacy Policy",
      mainHeading_text: "",

      theDetails: [
        {
          subHeading: "",
          description: [
            {
              value: "Effective Date: 16 January 2023 ",

            }
          ]
        },
        {
          subHeading: "",
          description: [
            {
              value: "This Privacy Policy applies to the services provided by Roku Digital, encompassing the Roku Digital website and/or mobile application (the “Platform”). This electronic agreement is formed under the Information Technology Act, 2000, and the rules made thereunder, as amended. ",
            }
          ]
        },


        {
          subHeading: "PERMISSIONS:",
          description: [
            {
              value: "We are using the following permission set for only input purposes. These permissions are not used to store any type of personal information from the user. ",
            },
            {
              list: [
                {
                  value: "android.permission.CAMERA",
                },
                {
                  value: "android.permission.READ_PHONE_STATE",
                },
                {
                  value: "android.permission.GET_ACCOUNTS",
                },
                {
                  value: "android.permission.READ_CONTACTS",
                },
                {
                  value: "android.permission.FILES_IMAGES",
                }
              ],
              isOL: false,
              isList: true,
              value: ""

            }

          ]
        },
        {
          subHeading: "Explanation:",
          description: [
            {
              value: "Above authorizations are utilized distinctly to capture data from the camera, read phone state, read contact for reading contact details for an emergency call, and read files for document verification. Data of users taken under an authorization is properly secured and under the law of Google consent.",
            },
            {
              value: "Location Permission Declaration:"
            },
            {
              list: [
                {
                  value: "ACCESS_FINE_LOCATION"
                },
                {
                  value: "ACCESS_COARSE_LOCATION"
                },
                {
                  value: "ACCESS_BACKGROUND_LOCATION"
                }
              ],
              isOL: true,
              isList: true,
              value: ""
            }
          ]
        },
        {
          subHeading: "ACCESS_FINE_LOCATION & ACCESS_COARSE_LOCATION",
          description: [
            {
              value: "Used for access location after allowing the user for accurate delivery of the product to the customer's door. Location service is used under the privacy policy of Google Play Developer Program Policies. We don’t share any type of location data or customer information for any additional resources. We ensure to the customer for safe and accurate delivery with privacy taking under an authorization is appropriately secure and under the law of google consents. ",
            },

          ]
        },
        {
          subHeading: "ACCESS_BACKGROUND_LOCATION",
          description: [
            {
              value: "Used for tracking on the user's side. Location service is used under the privacy policy of Google Play Developer Program Policies. We don’t share any type of location data or customer information for any additional resources taking under an authorization is appropriately secure and under the law of google consents.  ",
            },

          ]
        },
        {
          subHeading: "CONTACT INFORMATION:",
          description: [
            {
              value: "We welcome your comments regarding this privacy statement at the contact address given at the App. Should there be any concerns about contravention of this Privacy Policy, we will employ all commercially reasonable efforts to address the same. The terms in this Policy may be changed by us at any time. Should you have concerns regarding this Policy or the Services that we offer, please feel free to write to us.  ",
            },
            {
              value: "Collecting and Using Your Personal Data: "
            }

          ]
        },
        {
          subHeading: "Types of Data Collected:",
          description: [
            {
              list: [

                {
                  value: "Personal Data:",
                },
                {
                  value: "Email address:",
                },
                {
                  value: "First name and last name:",
                },
                {
                  value: "Phone number:",
                },
                {
                  value: "PAddress, State, Province, ZIP/Postal code, City:",
                },
                {
                  value: "Usage Data",
                },
                {
                  value: "SMS for one-time verification:",
                },
                {
                  value: "Files - Images, Documents:",
                }

              ],
              isOL: false,
              isList: true,
              value: ""
            }
          ]
        },

        {
          subHeading: "Stay Explanation:",
          description: [
            {
              value: "SMS permission is needed for one-time verification of the user's mobile number. Images are required to verify the documents like id proof of the client.",
            },
            {
              value: "Information Collected while Using the Application: ",
            },
            {
              value: "  While using our application, with your prior permission, we may collect:",
            },
            {
              list: [
                {
                  value: "  Information regarding your location",
                },
                {
                  value: " Information from your Device’s phone book (contacts list)",
                },
                {
                  value: "Information from your Device's Files (Images)",
                }

              ],

              isOL: false,
              isList: true,
              value: ""
            },
            {
              value: "We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company’s servers and/or a Service Provider’s server or it may be simply stored on Your device. You can enable or disable access to this information at any time, through Your Device settings. ",
            }
          ]
        },
        {
          subHeading: "INFORMATION COLLECTED AND USE OF INFORMATION:",
          description: [
            {
              value: " We collect certain information, including personal details, when",
            },
            {
              list: [
                {
                  value: " You use or register on the Platform.",
                },
                {
                  value: " You avail our services, such as location, Platform usage, and device data.",
                },
                {
                  value: " You communicate with us through inquiries, grievances, or feedback.",
                }

              ],
              isOL: false,
              isList: true,
              value: ""
            }
          ]
        },
        {
          subHeading: "RETENTION:",
          description: [
            {
              value: "All information, including personal details, collected under this Privacy Policy will be retained by Roku Digital for a duration permissible under Applicable Law. Users may request account deletion, but certain data may be retained for legal, regulatory, safety, security, fraud prevention, or account-related reasons. ",
            },

          ]
        },
        {
          subHeading: "RIGHTS:",
          description: [
            {
              value: "You have the right to access your profile at any time to add, remove, or modify information. Roku Digital commits to using only revised information post your modifications. You should provide accurate information",
            },

          ]
        },
        {
          subHeading: "DATA SECURITY:",
          description: [
            {
              value: "Roku Digital will implement organizational and technical measures required by applicable laws to protect the confidentiality and security of the information in its control. Data will be encrypted in transit as well as at rest.",
            },

          ]
        },
        {
          subHeading: "DISCLOSURES AND TRANSFER:",
          description: [
            {
              value: "We may share your information with third parties for various purposes, including but not   limited to:",
            },
            {
              list: [
                {
                  value: "Riders/Passengers: Sharing relevant information for trip coordination.",
                },
                {
                  value: "Identity Verification Providers: Third-party services to verify and conduct background checks on Drivers during the onboarding process",
                },
                {
                  value: "Hosting and IT Service Providers: Securely storing information, including personal and sensitive data.",

                },
                {
                  value: "Payment Processing Partners: Facilitating payment transactions.",
                }

              ],
              isOL: false,
              isList: true,
              value: ""
            }

          ]

        },
        {
          subHeading: "GRIEVANCES:",
          description: [
            {
              value: "For grievances, questions, concerns, or feedback related to information processing or this Privacy Policy, contact us at support@roku.taxi ",
            },
            {
              value: "To address complaints or discrepancies regarding Personal Information processing, reach out to the Grievance Officer:",

            },
            {
              value: "Rohan Kulkarni – support@roku.taxi"
            },

          ]
        },
        {
          subHeading: "UPDATES:",
          description: [
            {
              value: "We may update this notice, and significant changes will be communicated through the Roku Digital website, mobile application, or other means, such as email. Users are encouraged to review this notice periodically.  ",
            },

            {
              value: "Using our services after an update constitutes consent to the updated notice to the extent permitted by law.  ",
            }

          ]
        },


      ]

    }
    this.ContentDescriptionData = data
  }
}



