import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clock-in-clock-out',
  templateUrl: './clock-in-clock-out.component.html',
  styleUrls: ['./clock-in-clock-out.component.css']
})
export class ClockInClockOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
