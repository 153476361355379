import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RouterModule } from '@angular/router';
import { MaterialModule} from '../material';
import { HrPageComponent } from './hr-page.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { LeavesApprovalComponent } from './leaves-approval/leaves-approval.component';
import { SalaryManagementComponent } from './salary-management/salary-management.component';
import { DesignCertificateComponent } from './design-certificate/design-certificate.component';
import { ClockInApprovalsComponent } from './clock-in-approvals/clock-in-approvals.component';
import { HrPoliciesManagementComponent } from './hr-policies-management/hr-policies-management.component';
import { BirthdayAlertsComponent } from './birthday-alerts/birthday-alerts.component';
import { AchievementsPageComponent } from './achievements-page/achievements-page.component';
import { PaySlipsComponent } from './pay-slips/pay-slips.component';
import { ApplyCheckLeavesComponent } from './apply-check-leaves/apply-check-leaves.component';
import { ClockInClockOutComponent } from './clock-in-clock-out/clock-in-clock-out.component';
import { DisplayHrPoliciesComponent } from './display-hr-policies/display-hr-policies.component';


@NgModule({
  declarations: [HrPageComponent, LoginComponent, RegisterComponent, ProfilePageComponent, ControlPanelComponent, LeavesApprovalComponent, SalaryManagementComponent, DesignCertificateComponent, ClockInApprovalsComponent, HrPoliciesManagementComponent, BirthdayAlertsComponent, AchievementsPageComponent, PaySlipsComponent, ApplyCheckLeavesComponent, ClockInClockOutComponent, DisplayHrPoliciesComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ]
})
export class HrPageModule { }
