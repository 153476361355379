<div class="main_wrap">
    <div class="container">
      <div class="heading_line"><mat-divider></mat-divider></div>
      <div class="heading"> {{ImageDivisionDetails.mainHeading}} </div>
      <div class="heading_line"><mat-divider></mat-divider></div>
    </div>
    <div class="row section_box">
      <div class="col-md-4" *ngFor="let photos of ImageDivisionDetails.theImage">
          <img src="{{photos.image}}" class="image_txt">
          <p class="head_txt">{{photos.header}}</p>
          <div class="list" *ngIf="photos.isList">
            <li *ngFor="let values of photos.list">{{values.value}}</li>
          </div>
          <p class="des_txt">{{photos.description}}</p>
      </div>
    </div>
  </div>