<div class="footer">
    <p>Copyright © 2024 Roku - All Rights Reserved.</p>
    <p>Roku Digital Inc : 30 N Gould St Ste R Sheridan, WY, Unites States Of America, 82801</p>
    <p>Roku Digital : SM Plaza, As Rao Nagar, Hyderabad, TS, India, 500062</p>
    <p>Sharkify Technology Pvt Ltd: SM Plaza, As Rao Nagar, Hyderabad, TS, India, 500062</p>
    <p>Contact Details : +91 8790938772</p>
    <div id="linker_text">
        <a routerLink="/home" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Home</a>
        <a routerLink="/aboutus" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">About
            Us</a>
        <a routerLink="/careers" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">Careers</a>
        <a routerLink="/blogs" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">Blogs</a>
        <a routerLink="/rokushop" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Roku
            Shop</a>
        <a routerLink="/termsconditions" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a>
        <a routerLink="/privacypolicy" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a>
        <a routerLink="/contactus" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Contact
            Us</a>
    </div>
</div>