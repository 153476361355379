import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-certificate',
  templateUrl: './design-certificate.component.html',
  styleUrls: ['./design-certificate.component.css']
})
export class DesignCertificateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
