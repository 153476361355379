<div class="join-the-movement-main">
    <div class="join-the-movement-image">
        <img class="img"
            src="https://img1.wsimg.com/isteam/ip/1c0dc6bf-44c6-4e27-9086-95a76ea06c52/Happy_Smiling_Auto_Rickshaw.jpg/:/rs=w:1240,h:620,cg:true,m/cr=w:1240,h:620">
    </div>
    <div class="join-the-movement-main_heading">
        <div class="heading_line1"><mat-divider></mat-divider></div>
        <div class="join-the-movement-main_header">
            Join the Movement : 
        </div>
        <div class="heading_line2"><mat-divider></mat-divider></div>
    </div>
    <div class="description">
        <div class="join-the-movement-data">
            Join the <div class="join-the-movement-sub_heading"> "Roku" </div> either as a <div class="join-the-movement-sub_heading"> "Driving Partner"
            </div> or as a <div class="join-the-movement-sub_heading"> "Ride User"! </div>
        </div>
        <br>
        <div class="join-the-movement-data">
            Are you ready to be a part of something bigger?<br>
            Join us in shaping the future of mobility. Whether you're a driver looking for a increase earning potential
            or a user looking for a discounted rides, Roku is the platform where dreams meet drive.
        </div>
        <br>
        <div class="join-the-movement-sub_heading">
            Internship & Employment Opportunities at Roku Unleash your Potential!
        </div><br>
        <div class="join-the-movement-apply">
            <button class="Apply" (click)="redirectToForm()">APPLY NOW</button>
        </div>
    </div>
</div>