import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leaves-approval',
  templateUrl: './leaves-approval.component.html',
  styleUrls: ['./leaves-approval.component.css']
})
export class LeavesApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
