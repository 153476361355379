<div class="whole_page">
    <div class="section_part">
        <div class="row">
            <div class="col-md-6">
                <img src="/assets/rokushop.png" class="image_content" />
            </div>
            <div class="col-md-6">
                <div class="content_part">
                    <div class="heading_line">Coming Soon . . .</div>
                    <div class="text_line">Roku Shop: Elevate Your Style,</div>
                    <div class="text_line">Redeem Reward Points for the Best Brands!</div>
                    <div class="button_display">
                        <button class="gotohome">GO TO HOMEPAGE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>