import { Component, OnInit } from '@angular/core';
import { ContentDescriptionsData } from 'src/app/helpers/content-descriptions/content-descriptions.component';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor() { }
  ContentDescriptionsData; ContentDescriptionsData2;
  ngOnInit(): void {
    let data: ContentDescriptionsData = {
      mainHeading: "About Us",
      mainHeading_text: "   ",
      theDetails: [
        {
          subHeading: "Welcome to Roku",
          description: [
            {
              value: "Where innovation meets mobility and community thrives. At Roku, we are more than a ride-hailing platform; we are architects of a connected and empowered ecosystem. "
            }
          ]

        },
        {
          subHeading: "Our Mission",
          description: [
            {
              value: "To revolutionise the future of transportation by providing reliable and sustainable mobility solutions, fostering a supportive community, and empowering both drivers and users.  "
            }
          ]
        },
        {
          subHeading: "What Sets Us Apart",
          description: [
            {
              list: [
                {
                  value: "Supply-Friendly Platform: We prioritize fairness and ownership, creating a platform that's friendly to both our drivers and users.",
                },
                {
                  value: "Inclusive Community: We believe in building a diverse and interconnected community, where everyone's journey matters.",
                },
                {
                  value: "aInnovation in Mobility: From cutting-edge technology to unique benefits, we're reshaping the mobility landscape.",
                }
              ],
              isOL: false,
              isList: true,
              value: ""
            }
          ]
        }

      ]
    }
    this.ContentDescriptionsData = data;



    let data1: ContentDescriptionsData = {
      mainHeading: "My Story",
      mainHeading_text: "",
      theDetails: [
        {
          subHeading: "Rohan Kulkarni - Founder & Visionary - (Successpreneur of the year award -2023)",
          description: [
            {
              value: "Meet Rohan Kulkarni, the dynamic mind behind Roku, bringing a unique blend of legal acumen and business expertise to the forefront."
            }
          ]

        },
        {
          subHeading: "Education & Background",
          description: [
            {
              value: "Rohan holds an MBA from the prestigious IIM Kozhikode and a law degree from OU - Nalsar University. His diverse educational background lays the foundation for Roku's innovative approach in the realm of mobility. "
            }
          ]
        },
        {
          subHeading: "Professional Experience",
          description: [
            {
              value: "As a seasoned professional, Rohan has served as the Country Manager for Directa 24, a London-based fintech firm. His experience in the global fintech landscape brings a strategic perspective to Roku, elevating it beyond the conventional ride-hailing paradigm."
            },
            {
              value:"Visionary Leadership Rohan's leadership is characterized by a forward-thinking vision, where technology, law, and business converge to create a platform that goes beyond transportation – it creates experiences."
            },
            {
              value:"Join Rohan and the Roku team on this transformative journey, where expertise meets innovation to redefine the future of mobility."
            }
          ]
        }

      ]
    }
    this.ContentDescriptionsData2 = data1;
  }

}
