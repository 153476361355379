import { Component, OnInit } from '@angular/core';
import { UserReviewsData } from '../../helpers/text-division/text-division.component';
import { ContentDescriptionsData } from 'src/app/helpers/content-descriptions/content-descriptions.component';
import { ImageDivisionData } from 'src/app/helpers/image-division/image-division.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }
  UserReviewsData;
  UserReviewsanotherData;
  ContentDescriptionsData;
  ImageDivisionData;
  ngOnInit(): void {
    let data : UserReviewsData ={
      mainHeading  : "Buzz Is Around!",
      mainHeader_text  : "The Proof is in the Pudding",
      theProofs  : [
        {
          description:"As a prospective driver, I'm eagerly awaiting the launch of Roku. The promise of fair earnings, community support, and the innovative approach to benefits like insurance and education assistance has me excited to be part of this driving revolution.",
          name:"'Raju'",
        },
        {
          description:"Even before the official launch, I'm thrilled about becoming a Roku rider. The prospect of a user-centric platform, coupled with the anticipation of earning reward points for every journey, has me counting down the days. Roku seems like the ride-hailing experience I've been waiting for.       ",
          name:"'Anushka'"
        },
        {
          description:"The pre-launch buzz around Roku is building an incredible sense of community. The prospect of the driver's union, benefits, and a platform dedicated to both drivers and riders has us eagerly waiting to be part of this interconnected mobility ecosystem. Exciting times ahead! ",
          name:"'Preetham'"
        }
      ]
    }
    this.UserReviewsData = data;

    let data1: ContentDescriptionsData = {
      mainHeading: "Why Roku ?",
      mainHeading_text: "",
      theDetails: [
        {
          subHeading: "Supply Friendly, Ownership Driven",
          description: [
            {
              value: "We pride ourselves on being the most supply-friendly and ownership-driven platform. We believe that success is a shared journey, and as a startup, we are committed to providing our channel partners with cutting-edge technology at 0% commission. for drivers."
            }
          ]

        },
        {
          subHeading: "Drivers Union Stake and User Rewards",
          description: [
            {
              value: "Roku is not just a platform; it's a community. We are giving a 40% stake to the Drivers Union, ensuring their voice and contribution matter. For our users, we're introducing a rewarding experience - 50% reward points, because your journey with Roku deserves recognition. "
            }
          ]
        }
      ]
    }
    this.ContentDescriptionsData = data1;

    let data2: ImageDivisionData = {
      mainHeading: "Experience Roku",
      theImage: [
        {
          image:"https://img1.wsimg.com/isteam/ip/1c0dc6bf-44c6-4e27-9086-95a76ea06c52/auto%20rickshaw%20india%20Drivers%20union.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:365,h:365,cg:true/qt=q:33",
          header:"Largest Drivers Union",
          description:"Members of the driver's union have the authority to determine the app's operation, shaping their own future. Their benefits encompass insurance coverage, vehicle fitness, access to loans, and support for their children's education. ",
        },
        {
          image:"https://img1.wsimg.com/isteam/ip/1c0dc6bf-44c6-4e27-9086-95a76ea06c52/reward%20points.jpg/:/cr=t:4.02%25,l:0%25,w:94.34%25,h:94.34%25/rs=w:365,h:365,cg:true,m/qt=q:33",
          header:"User Rewards",
          description:"Your earned reward points are your ticket to a shopping spree! Redeem them seamlessly on the Roku shop site, where a world of diverse brands awaits. Your journey doesn't just end on the road – it extends to exciting purchases with every point. ",
        },
        {
          image:"https://img1.wsimg.com/isteam/ip/1c0dc6bf-44c6-4e27-9086-95a76ea06c52/Taxi.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:365,h:365,cg:true/qt=q:33",
          header:"Ride in Style",
          list: [
            {
              value: "Auto",
            },
            {
              value: "Cab",
            },
            {
              value: "Bike",
            }
          ],
          isList: true,
          description:""
        },
    ]
    }
    this.ImageDivisionData = data2;
  }

}
