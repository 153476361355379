import { Component, Inject, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog,MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  listOfCareers = [
    {
      name: "Telecaller",
      imageUrl: "assets/Telecallers.png",
      experience: "N/A",
      salary: "₹15,000 - ₹18,000 per month",
      incentive: "(plus attractive incentive)",
      description: "Roku Digital is seeking enthusiastic and motivated female Telecallers to join our dynamic team. Whether you are an experienced professional or a fresher looking to start your career, we welcome you to be part of our growth journey. This role involves engaging with potential customers, understanding their needs, and providing them with appropriate solutions.",

    },
    {
      name: "Sales Manager",
      imageUrl: "assets/Sales Manager@.png",
      experience: "2 + year",
      salary: "₹15,000 per month",
      incentive: "(plus petrol allowance and fair incentive)",
      description:"Sales Strategy and Execution :- Develop and implement effective sales strategies to achieve sales targets for housing loans, vehicle loans, and health insurance products. Identify potential clients and market segments to expand the customer base.",
    },
    {
      name: "Back-End Executive",
      imageUrl: "assets/Back-End Executive @.png",
      experience: "Fresher or 1 + year",
      salary: "₹13,000 - ₹15,000 per month",
      incentive: "(plus attractive incentive)",
      description:"Data Management and Processing :- Collect, organize, and manage data from various sources to ensure it is accurate and up-to-date. Input and update data into the company's database systems with precision and attention to detail. Perform regular data audits and quality checks to maintain data integrity.",
    },
    {
      name: "Team Lead, Marketing",
      imageUrl: "assets/Team Lead, Marketing@.png",
      experience: "1 + year",
      salary: "₹25,000 - ₹30,000 per month",
      incentive: "(plus attractive incentive)",
      description:"Leadership and Team Management :- Lead, mentor, and motivate a team of marketing professionals to achieve individual and team goals. Conduct regular team meetings to review performance, set goals, and plan upcoming marketing campaigns. Foster a positive and productive team culture, encouraging collaboration and continuous learning.",
    },
    {
      name: "Video Editor",
      imageUrl: "assets/Video Editor.png",
      experience: "2 + year",
      description:"This is a full-time on-site role looking for 2+ years experience of Senior Video Editor at Roku Digital Inc. As a Senior Video Editor, you will be responsible for day-to-day tasks including video production, video editing, video color grading, motion graphics, and graphics editing. You will play a key role in creating visually engaging content to enhance the user experience.",
    },
    {
      name: "Digital Marketing Specialist",
      imageUrl: "assets/Digital Marketing Specialist.png",
      experience: "2 + year",   
      description:"Develop and implement digital marketing strategies to drive brand awareness and lead generation.Manage social media channels, content creation, and community engagement. Analyze campaign performance and optimize strategies for maximum impact. Collaborate with cross-functional teams to align marketing efforts with business objectives. Stay updated on industry trends and best practices to innovate our digital marketing approach.",   
    }
  ];

    

  constructor(public dialog:MatDialog) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    };
  }
  
  screenWidth;
  screenHeight;
  openShowMore(name : string) {
    console.log(name)
    const dialogRef = this.dialog.open(ShowMoreDailog,{
      height : '350px',
      width :'700px',
      data : {
        Name : name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  
  ngOnInit(): void {
    this.listOfCareers.forEach(career => {
      if (!career.salary) {
        career.salary = '';
      }
      if (!career.incentive) {
        career.incentive = '';
      }
      if (!career.experience) {
        career.experience = 'N/A';
      }
    });
  }
  toggleOverlay(career: any): void {
    career.showOverlay = !career.showOverlay;
    
  }
}
  

export interface showmoreData {
  Name : string
}
@Component({
  selector: 'app-showmore',
  templateUrl: './showmore.component.html',
  styleUrls: ['./showmore.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShowMoreDailog {
  constructor( public datepipe : DatePipe, public dialogRef: MatDialogRef<ShowMoreDailog>,@Inject(MAT_DIALOG_DATA) public data : showmoreData) 
  {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    };
  }
  screenWidth;
  screenHeight;
  Digital_Marketing_Specialist;
  Video_Editing;
  Team_Lead_Marketing;
  Back_End_Executive;
  Sales_Manager;
  Telecaller;
    
  onNoClick(): void {
    console.log(this.data.Name)
    
    this.dialogRef.close();
  }
  ngOnInit(){
    if(this.data.Name == "Telecaller"){
      this.Telecaller = true;
      this.Digital_Marketing_Specialist = false;
      this.Video_Editing = false;
      this.Team_Lead_Marketing = false;
      this.Back_End_Executive = false;
      this.Sales_Manager = false;
    }else if(this.data.Name == "Sales Manager"){
      this.Telecaller = false;
      this.Digital_Marketing_Specialist = false;
      this.Video_Editing = false;
      this.Team_Lead_Marketing = false;
      this.Back_End_Executive = false;
      this.Sales_Manager = true;
    }else if(this.data.Name == "Back-End Executive"){
      this.Telecaller = false;
      this.Digital_Marketing_Specialist = false;
      this.Video_Editing = false;
      this.Team_Lead_Marketing = false;
      this.Back_End_Executive = true;
      this.Sales_Manager = false;
    }else if(this.data.Name == "Team Lead, Marketing"){
      this.Telecaller = false;
      this.Digital_Marketing_Specialist = false;
      this.Video_Editing = false;
      this.Team_Lead_Marketing = true;
      this.Back_End_Executive = false;
      this.Sales_Manager = false;
    }else if(this.data.Name == "Video Editor"){
      this.Telecaller = false;
      this.Digital_Marketing_Specialist = false;
      this.Video_Editing = true;
      this.Team_Lead_Marketing = false;
      this.Back_End_Executive = false;
      this.Sales_Manager = false;
    }else if(this.data.Name == "Digital Marketing Specialist"){
      this.Telecaller = false;
      this.Digital_Marketing_Specialist = true;
      this.Video_Editing = false;
      this.Team_Lead_Marketing = false;
      this.Back_End_Executive = false;
      this.Sales_Manager = false;
    }
  }
}