import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() {
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }
   }
   screenWidth;
   screenHeight;

  ngOnInit(): void {
  }
  FileSelected
  SelectedFile(value : number){
    switch(value){
      case 0 : {
        this.FileSelected = "home";
      }
      break;
      case 1 : {
        this.FileSelected = "aboutus";
      }
      break;
      case 2 : {
        this.FileSelected = "careers";
      }
      break;
      case 3 : {
        this.FileSelected = "rokushop";
      }
      break;
      case 4 : {
        this.FileSelected = "termsconditions";
      }
      break;
      case 5 : {
        this.FileSelected = "privacypolicy";
      }
      break;
      case 6 : {
        this.FileSelected = "contactus";
      }
      break;
      default : {
        this.FileSelected = "home";
      }
    }
  }
}
