import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  sendMessage() {
    const phoneNumber = '7386917770';
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    const message = 'Hello!'; 
 
    window.open(whatsappUrl, '_blank');
  }
  currentDay: number = new Date().getDay();
    officeHours: any = {
      0: "Closed", // Sunday
      1: "9:00 AM - 5:00 PM", // Monday
      2: "9:00 AM - 5:00 PM", // Tuesday
      3: "9:00 AM - 5:00 PM", // Wednesday
      4: "9:00 AM - 5:00 PM", // Thursday
      5: "9:00 AM - 5:00 PM", // Friday
      6: "9:00 AM - 1:00 PM" // Saturday
    };
    showHours: boolean = false;
    showForm: boolean = false;
    formData: any = {}; // Object to hold form data
  
    toggleForm() {
      this.showForm = !this.showForm;
    }
  
    submitForm() {
      // Here you can handle form submission, e.g., send data to backend or perform validation
      console.log('Form submitted:', this.formData);
      // Reset form data
      this.formData = {};
      // Hide the form after submission
      this.showForm = false;
    }
  
    showContactForm: boolean = false;
  
  constructor() { }
  // maps="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15222.663930439076!2d78.564095!3d17.475694!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b65ecf50bc5%3A0x58b374bf51566908!2sRoku%20Digital!5e0!3m2!1sen!2sin!4v1710153312650!5m2!1sen!2sin";

  ngOnInit(): void {
  }
  toggleHours(): void {
    this.showHours = !this.showHours;
  }
  isdisplayTimings : boolean = false;
  Open(){
    this.isdisplayTimings = !this.isdisplayTimings;
    console.log(this.isdisplayTimings)
  }
}
